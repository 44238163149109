import React from "react";
import HeaterList from "../components/heater-list"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"

export default function Electric({ data, pageContext }) {
  return (
    <Layout>
    
      <SEO
        title="Table-top Patio Heaters"
        keywords={[`table-top patio heaters`]}
        description="Perfectly sized, up close heat from a table-top heater"
      />

<Hero heading='Table-top Patio Heaters' subheading='Portable small outdoor hear' />

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">The best table-top patio heaters.</h3>
<p className="text-gray-600">We've hand-picked the top patio heaters on the market for you for explore.<br /><br /></p>
<HeaterList data= {data.products} />
</div>
</div>

    </Layout>
  );
}

export const query = graphql`
  query {
    products: allGoogleSheetProductsRow(filter: {fitting: {eq: "Table top"}}) {
      edges {
        node {
          productImage
          productName
        }
      }
    }
  }
`